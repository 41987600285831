import { TrackingPayloadData } from './tracking/TrackingPayload';
import { Action, Feature } from '@otto-ec/tracking-bct';
import { logger } from '@otto-ec/global-resources/debug';
import type { Debug } from "@otto-ec/global-resources/debug";

/**
 *
 */
export const serviceLogger = logger('up.teaser');

/**
 *
 *
 *
 *
 *
 *
 */
export function logTrackingWithPayload(log: Debug, topic: string, trackingPayload?: TrackingPayloadData) {
	log.debug(`Tracking event sent to topic: ${topic}`);
	if (trackingPayload) {
		log.debug(trackingPayload);
	}
}

export function logSendMergeFeatureTracking(log: Debug, features: Feature[] = []) {
	if (features.length > 0) {
		log.debug(`Merge event feature tracking sent: ${JSON.stringify(features)}`);
	}
}

export function logSubmitEventFeatureTracking(log: Debug, action?: Action) {
	if (action) {
		log.debug(`Submit event feature tracking sent: ${JSON.stringify(action)}`);
	}
}
